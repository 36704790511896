<template>
  <div class="faq_list">
    <div class="container">
      <h3 class="title">Frequently Asked Questions</h3>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item v-for="(item, index) in faqList" :id="item.id" :key="index" :title="item.title" :name="item.name">
          <div v-html="item.dec" />
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'],
      faqList: [
        {
          name: '1',
          id: 'question-1',
          title: '1.What is KKash?',
          dec: 'KKash is your one-stop online loan platform, it is an online lending marketplace that offers customers a wide range of options based on big data analysis.Our partnership spans some of the best loan providers in Kenya who provide their services through mobile apps.KKash acts as the bridge between loan providers and customers through technology. We rely on big data analysis to understand customer needs and recommend suitable financial products to them. We enable our users to get quick, easy, and reliable loan services without the need for branch visits. Our application process is 100% online.Empower yourself with anytime-anywhere loan access. '
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.What our product KKash offers?',
          dec: 'Our product could offer our customers microloan to use at every aspect of day-to-day life.'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.What products does KKash offer?',
          dec: 'KKash is an Instant Personal Loan Platform for salaryman , a one-stop loan platform.  KKash offers various loan products with different maturities and amounts.The application process is completely online and support 24*7*365. Just three steps no more than 10 minutes. The cash is immediately transferred to the bank account of user. Compare to the 4-7 days to avail a loan from a bank, which also requires a lot of documentation and frequent visits to the branch office,KKash will be a good helper to solve your temporary turnaround difficulties.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.How to contact KKash?',
          dec: 'You can contact us via email:cs@kopalend.com'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.Who can apply for a loan from loan products via KKash?',
          dec: 'Kenyan Individual<br>Adults<br>has a monthly source of income.'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6.What\'s the overview of loan products on KKash?',
          dec: 'Tenure: Customer could see completely full details of Tenure and interests in Contract.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7.What is the basis for reviewing of the products on KKash?',
          dec: 'Based on the basic information you submitted, KYC documents and your historical repayments. Among them, lenders need your Identity code to verify your identity and need your Personal identification card to prove your address.'
        },
        {
          name: '8',
          id: 'question-8',
          title: '8. What are the documents I must submit for the loan？',
          dec: 'each user who applies for a personal loan will be required to submit three kinds of document: a. Proof of Identity: We collect your Personally identified details as a Photo ID and Proof of Identity and is mandatory;b. Proof of Address: You may provide us with a copy of your printed Personal identification(masked) or Passport or Vehicle’s card.Each user must mandatorily submit the Proof of Identity along with one of the documents mentioned above as Address Proof. Please note that submission of Personal identification is voluntary and you may wish to submit any one of the other alternatives provided above.'
        },
        {
          name: '9',
          id: 'question-9',
          title: '9.I am unable to get the OTP for E-sign on my phone. What could be the possible reason?',
          dec: "You will receive two OTPs once you click on the 'e-sign' via digio option. The first OTP will be sent to the same mobile number with which you have registered with us. The OTP is delivered by your network operator and network issues can prevent you from receiving the OTP. In case of any issues in this OTP, try again after switching off and restarting your phone. Or else, you can try after some time.The second OTP will be sent to the contact number that is linked to your Personal identification. In case you don't have ready access to the same, you may find it at the Local authourity website. The OTP would also be delivered to the E-mail ID linked to your Personal identification. If you still don't receive the second OTP, please reach out Local authourity or your nearest Personal identification centre."
        },
        {
          name: '10',
          id: 'question-10',
          title: '10. I can not receive the verification code for registration or login？',
          dec: "Please make sure your mobile phone number is correct. If you still can't receive the verification code, it may be the operator's fault. Please re-send otp later or contact customer service via email:cs@kopalend.com"
        },
        {
          name: '11',
          id: 'question-11',
          title: '11. My KYC file is correct, but why didn\'t it pass?',
          dec: 'Please make sure that the file you uploaded is clear and not a remake of the photo.'
        },
        {
          name: '12',
          id: 'question-12',
          title: '12. Why is my application not approved?',
          dec: 'When we review a loan, we will review it from multiple dimensions. If your loan is not approved, this will not affect your credit. It may just be because your situation does not match our rules and credit model. If you are rejected by the review, we will let you know when you can apply again on the homepage.'
        },
        {
          name: '13',
          id: 'question-13',
          title: '13. Why didn\'t I receive the audit result?',
          dec: 'Because the review data takes a certain amount of time, the audit result will be given at the latest 1 working day. If it exceeds 1 working day, please contact customer service in time: <br>cs@kopalend.com'
        },
        {
          name: '14',
          id: 'question-142',
          title: '14. Why is my bank account not verified?',
          dec: 'Please confirm that your bank account number is yours. Please confirm that your personal ID code and bank account are not filled in incorrectly. Make sure your account is a savings account and not a fixed asset account, credit account or virtual account.'
        },
        {
          name: '15',
          id: 'question-15',
          title: '15. What\'s mobile linked with Personal identification and why is it required?',
          dec: 'Mobile linked with Personal identification is the mobile number which is updated in your Personal identification. For e-signing the Loan Agreement, Local authourity will send you an OTP on this number. You can get your Mobile Number linked to your Personal identification Card. And to know the process, you may click here.'
        },
        {
          name: '16',
          id: 'question-16',
          title: '16. Why should I e-sign the Loan Agreement?',
          dec: 'The Loan Agreement is a legally binding agreement that you need to sign before availing a Loan. The agreement states that once a loan is disbursed to you, you are liable to pay back the entire amount with interest and other charges as applicable within the maturity of the Loan.'
        },
        {
          name: '17',
          id: 'question-17',
          title: '17. How will I receive the loan?',
          dec: 'When you fill out your personal information, we will ask you to provide your bank account and we will verify your account. After you sign the agreement, lender on KKash will directly transfer the money into your bank account.'
        },
        {
          name: '18',
          id: 'question-18',
          title: '18. I did not receive the loan on time, will the repayment date be recalculated?',
          dec: 'Lenders will start to calculate interest when the money is successfully transferred.'
        },
        {
          name: '19',
          id: 'question-19',
          title: '19. Why are the processing fees charges deducted from my loan amount upfront?',
          dec: 'Since there is some operational effort that goes into performing credit checks and sanctioning the loan, the processing fee is charged upfront from the loan amount. However, this is a standard practice followed by all financial entities.'
        },
        {
          name: '20',
          id: 'question-20',
          title: '20. When do I need to repay?',
          dec: 'You can log in to the app to see your repayment date form Records page.'
        },
        {
          name: '21',
          id: 'question-21',
          title: '21. How can I repay my money?',
          dec: 'If lenders have successfully disbursed to you, you can click on the Records page of the app repayment homepage, and through the payment gateway repayment, Lenders offer three repayment methods: Debit Card, Netbanking, wallet, and ways clearly agreed in contract.'
        },
        {
          name: '22',
          id: 'question-22',
          title: '22. Is there a fee for repayment?',
          dec: 'A third party will charge you a fee, and you are advised to use netbanking to repay it because fees are relatively low. '
        },
        {
          name: '23',
          id: 'question-23',
          title: '23. What happens if I don\'t pay back on time?',
          dec: 'Lenders will charge penalty fee per day. Your Credit score will be updated as a defaulter with credit rating agencies which will make it difficult for you to take loans with any bank or financial institution in the future. Companies also check an individual\'s credit score and may not offer you employment if your credit score is bad.'
        },
        {
          name: '24',
          id: 'question-24',
          title: '24. Can I repay in advance?',
          dec: 'Prepayment charges will be applicable as per the interest rate as prescribed.'
        },
        {
          name: '25',
          id: 'question-25',
          title: '25. Can I extend the loan period?',
          dec: 'It depends on lender\'s conditions.'
        },
        {
          name: '26',
          id: 'question-26',
          title: '26. How can I confirm that I have repaid successfully?',
          dec: 'When you pay off, KKash will notify you by text message. At the same time, log in to the app to see the records you have repaid.'
        },
        {
          name: '27',
          id: 'question-27',
          title: '27. When I borrow again, do I still need to fill in my personal information?',
          dec: 'You do not need to submit your information again, but if your information changes, please update your information on app in time or contact customer service to change. In this regard, we will re-review.'
        },
        {
          name: '28',
          id: 'question-28',
          title: '28. I can not receive the verification code for registration or login.',
          dec: 'Please make sure your mobile phone number is correct. If you still can\'t receive the verification code, it may be the operator\'s fault. Please re-send otp later or contact customer service.'
        },
        {
          name: '29',
          id: 'question-29',
          title: '29. Why can\'t I apply for a higher amount?',
          dec: 'According to your credit and repayment records, your credit score will gradually accumulate and the amount will gradually increase.'
        },
        {
          name: '30',
          id: 'question-30',
          title: '30. Is my data safe with KKash?',
          dec: 'Yes, your data is safe with us. It is transferred over a secure connection to us, and we do not share it with anyone without your consent, except with the lenders.'
        },
        {
          name: '31',
          id: 'question-31',
          title: '31. What is the interest charged on the loans that I avail with KKash?',
          dec: '31.For all produts, Lender will charge a rate of interest based on terms and conditions agreed by customers.'
        },
        {
          name: '32',
          id: 'question-32',
          title: '32. What is the processing fee charged on loans taken?',
          dec: '32.For more information, please see the terms and conditions.'
        },
        {
          name: '33',
          id: 'question-33',
          title: '34. Can I borrow several loans at the same time?',
          dec: 'Yes. At the same time, you can apply several loans , but lenders will review your credit. '
        },
        {
          name: '34',
          id: 'question-34',
          title: '35. Can I cancel my loan?',
          dec: 'Once the loan application has been submitted, the loan can\'t be cancelled. But you can alse contact to KKash or directly contact to lenders'
        }
      ]
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    }
  }

}
</script>

<style lang="scss" scoped>
.faq_list{
  padding: 50px 0;
  width: 1200px;
  margin: 0 auto;
  .title{
    font-size: 30px;
    color: #333333;
    margin-bottom: 40px;
  }
}
</style>
